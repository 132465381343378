<template>
  <div :class="{
    'aside-container': true,
    'aside-container-min': !$store.state.sidebarStatus,
  }">
    <div class="logo">
      <img class="bigImg" v-if="$store.state.sidebarStatus && !$store.state.user.logoUrl"
        src="../assets/img/asideVideo/logo.png" />
      <img class="bigImg" v-if="$store.state.sidebarStatus && $store.state.user.logoUrl"
        :src="$store.state.user.logoUrl" />
      <img class="smallImg" v-if="!$store.state.user.logoUrl && !$store.state.sidebarStatus"
        src="../assets/img/asideVideo/logo-min.png" />
      <img class="smallImg" v-if="$store.state.user.logoUrl && !$store.state.sidebarStatus"
        :src="$store.state.user.logoUrl" />
    </div>
    <ul onselectstart="return false" class="aside-ul">
      <li :class="{ active: activeStore == val.path }" v-for="(val, index) in routerArr" :key="index">
        <el-tooltip class="item" effect="dark" :content="val.name" placement="left"
          :manual="$store.state.sidebarStatus">
          <div class="aside-li-div" @click="GoPath(val.path)">
            <i :class="['nav', val.iocn]"></i>
            <span>{{ val.name }}</span>
          </div>
        </el-tooltip>
      </li>
    </ul>
    <div v-if="$store.state.sidebarStatus" class="bottom-box">
      <div class="bottom-title">
        <p>版权所有 © copy 2021</p>
        <p>西安康奈网络科技有限公司</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeStore: this.$route.meta.activePath,
      routerArr: [
        {
          name: "首页",
          path: "/index",
          show: true,
          iocn: "nav1"
        },
        {
          name: "话题分析",
          path: "/topics",
          show: true,
          iocn: "nav5"
        },
        {
          name: "舆情报告",
          path: "/report",
          show: true,
          iocn: "nav6"
        },
        {
          name: "综合分析",
          path: "/analyse",
          show: true,
          iocn: "nav7"
        },
        {
          name: "监测方案",
          path: "/monitoringScheme",
          show: true,
          iocn: "nav8"
        },
        {
          name: "云搜索",
          path: "/cloudSsearch",
          show: true,
          iocn: "nav9"
        },
        {
          name: "热榜数据",
          path: "/hotData",
          show: true,
          iocn: "nav10"
        },
        {
          name: "系统设置",
          path: "/systemSettings",
          show: true,
          iocn: "nav11"
        }
      ]
    };
  },
  watch: {
    $route(to) {
      this.activeStore = to.meta.activePath;
    }
  },
  methods: {
    GoPath(path) {
      sessionStorage.removeItem("setType");
      sessionStorage.removeItem("setTypejn");
      sessionStorage.removeItem("setTypejy");
      sessionStorage.removeItem("defaultId");
      sessionStorage.removeItem("abroaddefaultId");
      this.$router.push({ path: path });
      sessionStorage.setItem("activeName", "first");
      sessionStorage.setItem("names", "");
      sessionStorage.setItem("defultName", "");
      sessionStorage.setItem("cardActive4", "");
      this.$store.state.activeName = "first";
      sessionStorage.setItem("activeReport", "");
      sessionStorage.setItem("active", "");
      sessionStorage.setItem("activeName1", "second");
      sessionStorage.setItem("eventName", "generalization");
      sessionStorage.setItem("setting", "no");
      this.$store.state.active = "";
      this.clearSeccion();
    },
    clearSeccion() {
      //监测方案里要保存的seccion
      let arr = [
        "defaultId",
        "defaultType",
        "abroaddefaultId",
        "abroaddefaultType",
        "followdefaultId",
        "followdefaultType"
      ];
      arr.forEach(v => {
        sessionStorage.removeItem(v);
      });
    }
  },
  created() {
    let _index;
    let _index1;
    if (this.$store.state.user.scope[0]) {
      this.routerArr.forEach((item, index) => {
        if (item.name == "云搜索") {
          _index1 = index;
        }
      });
      this.routerArr.splice(_index1, 0, {
        name: "实时信息",
        path: "/message",
        show: true,
        iocn: "nav2"
      });
      if (this.$store.state.user.scope[3]) {
        this.routerArr.splice(1, 0, {
          name: "精准舆情",
          path: "/sentiment",
          show: true,
          iocn: "nav3"
        });
        if (this.$store.state.user.organizationId === 2500 || this.$store.state.user.organizationId === 744) {
          this.routerArr.splice(2, 0, {
            name: "预警舆情",
            path: "/warningMessage",
            show: true,
            iocn: "nav14"
          });
        }
      }
    }
    if (this.$store.state.user.scope[1]) {
      this.routerArr.forEach((item, index) => {
        if (item.name == "话题分析") {
          _index = index;
        }
      });
      this.routerArr.splice(_index, 0, {
        name: "境外敏感信息",
        path: "/sensitiveMessage",
        show: true,
        iocn: "nav4"
      });
    }
    if (this.$store.state.user.scope[4]) {
      this.routerArr.splice(2, 0, {
        name: "视频监测",
        path: "/videoModule",
        show: true,
        iocn: "nav12"
      });
    }
  }
};
</script>

<style scoped="scss">
.aside-container {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: inherit;
  margin-right: -17px;
  margin-bottom: -17px;
  position: relative;
  background: url(../assets/img/aside/asideBg.png) no-repeat bottom;
}

.aside-container .logo {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 2px auto 0;
}

.aside-ul {
  width: 100%;
  margin-top: 33px;
}

.aside-ul li {
  width: 100%;
  height: 48px;
  cursor: pointer;
}

.aside-ul li:hover {
  opacity: 0.8;
}

.aside-ul li.active:hover {
  opacity: 1;
}

.aside-ul li+li {
  margin-top: 16px;
}

.aside-ul li.active .aside-li-div {
  background: #f5f8ff;
}

.aside-ul li.active span {
  background: #f5f8ff;
  color: #232431;
}

.aside-li-div {
  width: 180px;
  height: 48px;
  border-radius: 8px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 0 8px 0 20px;
}

.aside-li-div span {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}

.aside-li-div .nav {
  margin-right: 12px;
  width: 30px;
  height: 32px;
  display: inline-block;
  background: url(../assets/img/aside/nav.png) no-repeat;
}

.aside-li-div .nav.nav1 {
  background-position: 0 0;
}

.aside-li-div .nav.nav2 {
  background-position: 0 -32px;
}

.aside-li-div .nav.nav3 {
  background-position: 0 -64px;
}

.aside-li-div .nav.nav4 {
  background-position: 0 -96px;
}

.aside-li-div .nav.nav5 {
  background-position: 0 -128px;
}

.aside-li-div .nav.nav6 {
  background-position: 0 -160px;
}

.aside-li-div .nav.nav7 {
  background-position: 0 -192px;
}

.aside-li-div .nav.nav8 {
  background-position: 0 -224px;
}

.aside-li-div .nav.nav9 {
  background-position: 0 -256px;
}

.aside-li-div .nav.nav10 {
  background-position: 0 -288px;
}

.aside-li-div .nav.nav11 {
  background-position: 0 -320px;
}

.aside-li-div .nav.nav12 {
  background-position: 0 -360px;
}

.aside-li-div .nav.nav13 {
  background-position: 0 -404px;
}

.aside-li-div .nav.nav14 {
  background-position: 0 -444px;
}

.active .aside-li-div .nav.nav1 {
  background-position: -38px 0;
}

.active .aside-li-div .nav.nav2 {
  background-position: -38px -32px;
}

.active .aside-li-div .nav.nav3 {
  background-position: -38px -64px;
}

.active .aside-li-div .nav.nav4 {
  background-position: -38px -96px;
}

.active .aside-li-div .nav.nav5 {
  background-position: -38px -128px;
}

.active .aside-li-div .nav.nav6 {
  background-position: -38px -160px;
}

.active .aside-li-div .nav.nav7 {
  background-position: -38px -192px;
}

.active .aside-li-div .nav.nav8 {
  background-position: -38px -224px;
}

.active .aside-li-div .nav.nav9 {
  background-position: -38px -256px;
}

.active .aside-li-div .nav.nav10 {
  background-position: -38px -288px;
}

.active .aside-li-div .nav.nav11 {
  background-position: -38px -320px;
}

.active .aside-li-div .nav.nav12 {
  background-position: -38px -360px;
}
.active .aside-li-div .nav.nav13 {
  background-position: -38px -404px;
}

.active .aside-li-div .nav.nav14 {
  background-position: -38px -444px;
}
/* 缩小 */
.aside-container-min .aside-li-div {
  width: 48px;
  padding: 0 9px;
}

.aside-container-min .aside-li-div .nav {
  margin-right: 0;
}

.aside-container-min .aside-li-div span {
  display: none;
}

.aside-li-div .number {
  height: 18px;
  padding: 0 6px;
  background: #ffb000;
  border-radius: 10px;
  font-size: 14px;
  color: #ffffff;
  margin-left: 16px;
  position: relative;
  top: 1px;
}

.active .aside-li-div .number {
  background: #ffb000;
  color: #ffffff;
}

.bottom-box {
  margin-top: 30px;
  width: 100%;
}

.bottom-title {
  text-align: center;
  font-size: 12px;
  color: #ffffff;
}

.bigImg {
  width: 140px;
  height: 32px;
  margin-top: 20px;
}

.smallImg {
  margin-top: 20px;
  width: 50px;
  height: 32px;
}
</style>
