<template>
  <div class="warning-div">
    <diV class="warning-header">
      <div class="flex">
        <div @click="setAudioPlay" style="cursor: pointer;width:20px">
          <img src="../assets/img/warning/inform.png" v-show="sound"/>
          <img src="../assets/img/warning/mute.png" v-show="!sound"/>
        </div>
        <span class="warning-header-title">消息通知</span>
      </div>
      <img
        src="../assets/img/warning/off.png"
        class="warning-off"
        @click="warningOff"
      />
    </diV>
    <div class="warning-body">
      <div
        v-for="(val, index) in list"
        :key="index"
        @click="jumpDetail(val)"
        class="warning-body-list"
      >
        <img src="../assets/img/warning/list2.png" />
        <!-- <img src="../assets/img/warning/list1.png" v-if="val.type == 2" /> -->
        <span class="warning-body-list-title">{{ val.title }}</span>
        <span class="warning-body-list-time">{{ val.time }}</span>
      </div>
    </div>
  </div>
</template>

<script>
function getCookie(){//获取cookie数据，返回值为cookie键值组成的json对象
	let jso = {};
	let arr = [];
	let coo = document.cookie;
	arr = coo.split("; ");
	arr.forEach(function(x){
		let arr2 = x.split("=");
		jso[arr2[0]] = arr2[1];
	});
	return jso;
}
function setCookie(key,value,time){//新增/修改cookie值，key为键名，value为值，time为是否设置长生命周期的cookie
	if(time == undefined){
		document.cookie = key + "=" + value;
	}else{
		let date = new Date();
		date.setDate(date.getDate() + time);
		document.cookie = key + "=" + value + ";expires = " + date;
	}
}
export default {
  data() {
    return {
      list: [],
      text: "",
      data: "",
      websocket: null,
      websocket2:null,
      audioUrl: require("@/assets/music/music1.mp3"),
      myAudio: new Audio(),
      timeout: 3000,
      timeoutObj: null,
      serverTimeoutObj: null,
      lockReconnect: false,
      sound:true
    };
  },
  beforeCreate() {},
  methods: {
    myAudios() {
      if(getCookie().audioPlay === "0"){
        this.sound = true;
        this.myAudio.play();
      }else{
        this.sound = false;
      }
    },
    warningOff() {
      this.$store.commit("warningOff", false);
      sessionStorage.setItem("warning", false);
      this.list = [];
      sessionStorage.removeItem("list");
    },
    start() {
      this.timeoutObj = setTimeout(() => {
        this.websocket.send(
          JSON.stringify({
            heartCheck: "heartCheck",
            token: sessionStorage.getItem("token"),
          })
        );
        this.serverTimeoutObj = setTimeout(() => {
          if (this.websocket) {
            this.reset();
          }
        }, this.timeout);
      }, this.timeout);
    },
    reset() {
      clearTimeout(this.timeoutObj);
      clearTimeout(this.serverTimeoutObj);
      this.start();
    },
    initWebSocket() {
      // 连接成功
      this.websocket.onopen = this.open;
      //连接错误
      this.websocket.onerror = this.setErrorMessage;
      //收到消息的回调
      this.websocket.onmessage = this.setOnmessageMessage;
      //监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
      window.onbeforeunload = this.onbeforeunload;
      this.websocket.onclose = this.setErrorMessage;
    },
    open() {
      this.start();
    },
    setErrorMessage() {
      let tt;
      if (this.lockReconnect) {
        return;
      }
      this.lockReconnect = true;
      tt && clearTimeout(tt);
      tt = setTimeout(() => {
        this.lockReconnect = false;
        if ("WebSocket" in window) {
          this.websocket = new WebSocket(
            this.$store.state.websocket+sessionStorage.getItem("token")
          );
          this.initWebSocket();
        }
      }, 5000);
    },
    setOnmessageMessage(event) {
      let message = JSON.parse(event.data);
      // 自身心跳重置
      this.reset();
      // 响应心跳
      if (message&&message.flag===0) {
        this.$store.commit("setWarningInfo");
        this.list.unshift(message);
        if(this.list.length>500){
          this.list.pop();
        }
        this.resetSetItem("list", JSON.stringify(this.list));
        this.$store.commit("warningOff", true);
        sessionStorage.setItem("warning", true);
        this.myAudios();
      }
    },
    onbeforeunload() {
      this.websocket.close();
    },
    // 跳转详情
    jumpDetail(item) {
      if (item.dataType == 0 || item.dataType == 4) {
        let info = {
          id: item.dataId,
          dataId: item.dataId,
        };
        // this.$router.push({
        //   path: "/index/accurateInfo",
        //   query: {
        //     info: encodeURIComponent(
        //       JSON.stringify({
        //         id: item.dataId,
        //         dataId: item.dataId,
        //       })
        //     ),
        //   },
        // });
        window.open(
          `#/index/accurateInfo?info=${encodeURIComponent(
            JSON.stringify(info)
          )}`
        );
      }
      if (item.dataType == 1) {
        let info = {
          id: item.dataId,
          type: "境外",
          activeName: "first",
        };
        // this.$router.push({
        //   path: "/index/accurateInfo",
        //   query: {
        //     info: encodeURIComponent(
        //       JSON.stringify({
        //         id: item.dataId,
        //         type: "境外",
        //         activeName: "first",
        //       })
        //     ),
        //   },
        // });
        window.open(
          `#/index/abroadInfo?info=${encodeURIComponent(JSON.stringify(info))}`
        );
      }
      if (item.dataType == 3) {
        window.open("#/hotData/index");
      }
      if(item.dataType == 5){
        let info = {teamId:item.countNum,dataId:item.dataId};
        window.open(`#/index/otherInfo?info=${encodeURIComponent(JSON.stringify(info))}`);
      }
      if(item.dataType == 7){
        let info = encodeURIComponent(JSON.stringify({id: item.dataId, organId: item.orgaId}));
        window.open(`#/message/index/original?info=${info}`);
      }
      if(item.dataType == 8){
        window.open(`#/message/index?info=warning`);
      }
    },
    //判断音频是否播放
    getAudioPlay(){
      if(getCookie().audioPlay==undefined){
        this.$http({
          url:"wss/push/getinfo/"+this.$store.state.user.userId,
          method: "get"
        }).then(res=>{
          if(res.data.code == 200){
            setCookie("audioPlay",res.data.data);
            this.sound = res.data.data === 0?true:false;
          }
        });
      }else{
        this.sound = getCookie().audioPlay === "0"?true:false;
      }
    },
    //修改音频播放状态
    setAudioPlay(){
      this.$http({
        url: "wss/push/update",
        method: "post",
        data:{
          "userId": this.$store.state.user.userId,
          "isOpen": this.sound?1:0
        }
      }).then(res=>{
        if(res.data.code == 200){
          this.sound = !this.sound;
          setCookie("audioPlay",this.sound?0:1);
        }
      });
    },
    websocket2set(){
      this.websocket2.onopen = ()=>{
        setInterval(() => {
          this.websocket2.send("xt");
        }, 10000);
      }
      this.websocket2.onmessage = (event)=>{
        if(event.data == "收到：xt"){
          return;
        }else{
          this.$store.commit("setWarningInfo");
          this.list.unshift(JSON.parse(event.data));
          this.$store.commit("warningOff", true);
          sessionStorage.setItem("warning", true);
          this.myAudios();
        }
      }
      //监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
      window.onbeforeunload = ()=>{
        this.websocket2.close();
      }
      this.websocket2.onerror = again;
      this.websocket2.onclose = again;
      let that = this;
      let judge = false;
      function again(){
        if(judge){
          return;
        }else{
          judge = true;
          setTimeout(() => {
            that.websocket2 = new WebSocket(
              "wss://www.yuqing.cn/wx/"+JSON.parse(sessionStorage.getItem("user")).unionid
            );
            that.websocket2set();
          }, 10000);
        }
      }
    }
  },
  created() {
    let listData = sessionStorage.getItem("list");
    if (listData == null) {
      this.list = [];
      this.$store.commit("warningOff", false);
    } else {
      this.list = JSON.parse(listData);
      this.$store.commit("warningOff", true);
    }
    this.myAudio.src = this.audioUrl;
    this.myAudio.preload;
    this.getAudioPlay();
  },
  mounted() {
    if ("WebSocket" in window) {
      this.websocket = new WebSocket(
        this.$store.state.websocket + sessionStorage.getItem("token")
      );
      this.initWebSocket();
    } else {
      this.$message.error("当前浏览器 Not support websocket");
    }
  },
  beforeDestroy() {
    this.onbeforeunload();
  },
};
</script>

<style scoped>
.warning-div {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.warning-header {
  width: 100%;
  height: 48px;
  background: linear-gradient(114deg, #16aef3 0%, #027ffe 100%);
  border-radius: 8px 8px 0 0;
  padding: 0 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.warning-header-title {
  font-size: 14px;
  color: #ffffff;
  margin-left: 18px;
}

.warning-off {
  cursor: pointer;
}

.warning-body {
  width: 100%;
  height: 224px;
  padding: 24px;
  overflow-y: auto;
}

.warning-body-list {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.warning-body-list + .warning-body-list {
  margin-top: 32px;
}

.warning-body-list img {
  margin-right: 18px;
}

.warning-body-list-title {
  font-size: 14px;
  color: #5a6474;
  width: 189px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 48px;
}

.warning-body-list-time {
  font-size: 14px;
  color: #b8bcc3;
  white-space: nowrap;
}
</style>
