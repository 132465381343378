<template>
  <div :class="{ layout: true, 'layout-min': !$store.state.sidebarStatus }">
    <div
      :class="{ 'layout-aside': $router.history.current.path !== '/videoModule/index', 'layout-aside-video': $router.history.current.path === '/videoModule/index' }">
      <Aside v-if="$router.history.current.path !== '/videoModule/index'" />
      <AsideVideo v-else />
    </div>
    <div :class="{
    'layout-header': true,
    'layout-header-long': !$store.state.sidebarStatus,
  }">
      <Header v-if="$router.history.current.path !== '/videoModule/index'" />
      <HeaderVideo v-else />
    </div>
    <!-- 预警提示 -->
    <div class="layout-main" @drop="drop($event)" @dragover="dragover($event)">
      <router-view ref="mainModule" />
      <transition name="el-zoom-in-bottom">
        <div v-show="$store.state.warning" class="layout-warning">
          <EarlyWarning/>
        </div>
      </transition>
      <CommonTools />
    </div>
    <el-popover placement="top" width="800" trigger="click" title="下载任务" v-if="downloadData.length > 0">
      <div class="listBody">
        <h2>根据数据量大小，下载通道建立时间可能较长</h2>
        <div v-for="(item, index) in downloadData" :key="index">
          <span :title="item.name">{{ item.exportName }}</span>
          <span v-if="item.state == 0">正在建立下载通道...</span>
          <span v-if="item.state == 1">下载中...</span>
          <span v-if="item.state == 2">创建任务失败</span>
          <span v-if="item.state == 3">下载成功</span>
          <span style="color: #0079fe; cursor: pointer" v-if="item.result != ''" @click="againDow(item)">{{ item.result
            }}</span>
        </div>
      </div>
      <div class="listFooter">
        <el-select v-model="operateData" placeholder="请选择操作" size="small" clearable @change="clearTask(operateData)">
          <el-option label="清空已完成记录" value="1"></el-option>
          <el-option label="清空失败记录" value="2"></el-option>
          <el-option label="清空全部记录" value="3"></el-option>
        </el-select>
      </div>
      <div class="exportBox el-icon-chat-dot-round" slot="reference" ref="exportButton"
        v-show="downloadData.length > 0">
        任务
        <span>{{ downloadData.length }}</span>
      </div>
    </el-popover>
    <!-- 下载提示 -->
    <el-dialog title="下载提示" :visible.sync="download.dialogFormVisible" width="700px" class="dialog-box"
      :close-on-click-modal="false" :close-on-press-escape="false" :append-to-body="true" :show-close="false">
      <div class="dialog-body">
        <span class="download-Wait" v-if="download.wite">正在下载...</span>
        <span class="download-Wait" v-else>下载完成！</span>
        <el-progress :stroke-width="22" :text-inside="true" :percentage="download.percentage"></el-progress>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="downloadYes" size="medium" type="primary" class="search_z">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="loginTipsShow" width="40%" :before-close="loginTipsClose">
      <div id="zb_layout_tips_main">
        <el-image :src="loginTips.url" v-show="loginTips.url"></el-image>
        <h2 v-html="loginTips.title"></h2>
        <p v-html="loginTips.text"></p>
      </div>
      <span slot="footer" class="zb_layout_tips dialog-footer">
        <el-button type="primary" size="small" @click="loginTipsClose">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Header from "../components/Header";
import HeaderVideo from "../components/HeaderVideo";
import Aside from "../components/Aside";
import AsideVideo from "../components/AsideVideo";
import EarlyWarning from "../components/EarlyWarning";
import CommonTools from "../components/commonTools";
import "element-ui/lib/theme-chalk/base.css";
import { organType, organTime } from "../api/index/index.js";

export default {
  components: {
    Header,
    HeaderVideo,
    Aside,
    AsideVideo,
    EarlyWarning,
    CommonTools,
  },
  data() {
    return {
      download: {
        dialogFormVisible: false,
        percentage: 0,
        wite: true,
        timeIndex: 0,
      },
      operateData: "",
      downloadData: [],
      setTimeID: 0,
      //机构类型提示弹窗
      loginTipsShow: false,
      loginTips: {
        url: "../assets/img/index/loginTrial.png",
        title: "",
        text: "",
        time: -1
      }
    };
  },
  methods: {
    dowLoadLink(objData) {
      if (objData.url !== undefined) {
        this.$http(objData)
          .then((res) => {
            if (res) {
              this.downloadFun(
                {
                  method: "get",
                  url: res.data.data.reportDocUrl,
                  params: {},
                  responseType: "blob",
                },
                res.data.data.reportName + ".docx"
              );
            }
          })
          .catch((err) => {
            console.warn(err);
          });
      }
    },
    // 新加实时刷新列表接口
    timeDreFresh(value) {
      this.$http({
        method: "post",
        url: "/api/topic/export/record",
        data: {
          userId: Number(
            JSON.parse(sessionStorage.getItem("user")).userId
          ),
        },
      })
        .then((res) => {
          if (res.data) {
            this.downloadData = res.data;
            if (value) {
              this.downloadData.push(value);
            }
            this.downloadData.forEach((item) => {
              if (item.url != "" && item.state == 1) {
                this.downloadUrl(item.url);
                this.setDowState(item);
              }
            });

          }
          clearTimeout(this.setTimeID)
          this.setTimeID = setTimeout(() => {
            this.timeDreFresh();
          }, 10000);
        })
        .catch((err) => {
          console.log(err);
          clearTimeout(this.setTimeID)
          this.setTimeID = setTimeout(() => {
            this.timeDreFresh();
          }, 10000);
        });
    },
    setDowState(item) {
      this.$http({
        method: "get",
        url: "/api/topic/export/download?id=" + item.id,
      })
        .then(() => {
          item.state = 3;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 清除任务列表数据  1表示成功  2表示失败
    clearTask(clearValue) {
      if (clearValue == "1") {
        this.delTaskList(clearValue);
      } else if (clearValue == "2") {
        this.delTaskList(clearValue);
      } else {
        this.delTaskList();
      }
    },
    // 删除任务
    delTaskList(value) {
      let data = {
        userId: Number(
          JSON.parse(sessionStorage.getItem("user")).userId
        ),
      };
      if (value == "1") {
        data.state = 1;
      } else if (value == "2") {
        data.state = 2;
      }
      this.$http({
        method: "post",
        url: "/api/topic/export/delExportExcel",
        data: data,
      })
        .then((res) => {
          if (res.data) {
            // this.taskList = res.data;
            // console.log(this.taskList);
            this.timeDreFresh();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //下载链接
    downloadUrl(url) {
      let link = document.createElement("a");
      // link.style.display = "none";
      link.href = url;
      // link.setAttribute("download", name);
      // document.body.appendChild(link);
      link.click();
      link.remove();
    },
    downLinkFile(obj) {
      //下载链接文件
      clearTimeout(this.download.timeIndex);
      if (obj) {
        this.download.dialogFormVisible = true;
        this.downloadProgress();
        this.$http(obj)
          .then((res) => {
            if (res && res.data.code == 200) {
              let a = document.createElement("a");
              a.href = res.data.data.url;
              if (obj.method == "get") {
                a.href = res.data.data.reportDocUrl;
              } else {
                a.href = res.data.data.url;
              }
              a.click();
              a.remove();
              clearTimeout(this.download.timeIndex);
              this.download.wite = false;
              this.download.percentage = 100;
            } else {
              this.$message.error("导出失败！");
              clearTimeout(this.download.timeIndex);
            }
          })
          .catch((err) => {
            this.$message.error("导出失败！");
            clearTimeout(this.download.timeIndex);
            console.warn(err);
          });
      }
    },
    downloadFun(obj, textName) {
      //文件下载
      clearTimeout(this.download.timeIndex);
      if (
        obj.url !== undefined &&
        (obj.data !== undefined || obj.params !== undefined) &&
        textName !== undefined
      ) {
        this.download.dialogFormVisible = true;
        this.downloadProgress();
        this.$http(obj)
          .then((res) => {
            if (
              res.status == 200 ||
              res ||
              res.data.code == 200 ||
              res.code == 200 ||
              res.data
            ) {
              let blob = new Blob([res.data], {
                type: "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              });
              if (window.navigator.msSaveOrOpenBlob) {
                //IE 10+
                navigator.msSaveBlob(blob);
              } else {
                // 非IE
                let elink = document.createElement("a");
                if (textName == 1) {
                  let name = decodeURIComponent(
                    res.headers["content-disposition"]
                  );
                  let index = name.indexOf("=");
                  elink.download = name.substring(index + 1, name.length);
                  // res.headers.content-disposition
                } else {
                  elink.download = textName;
                }
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                document.body.removeChild(elink);
              }
              clearTimeout(this.download.timeIndex);
              this.download.wite = false;
              this.download.percentage = 100;
            } else {
              this.$message.error("导出失败！");
              clearTimeout(this.download.timeIndex);
            }
          })
          .catch((err) => {
            this.$message.error("导出失败！");
            clearTimeout(this.download.timeIndex);
            console.warn(err);
          });
      }
    },
    downloadYes() {
      this.download.dialogFormVisible = false;
      this.download.percentage = 0;
      this.download.wite = true;
    },
    //下载进度模拟
    downloadProgress() {
      this.download.timeIndex = setTimeout(() => {
        if (this.download.percentage + 5 >= 90) {
          this.download.percentage = 90;
        } else {
          this.download.percentage += 5;
        }
        this.downloadProgress();
      }, 1000);
    },
    dragover(ev) {
      ev.preventDefault();
    },
    drop(ev) {
      if (ev.y < 80) {
        this.$store.state.commonTools = 80;
      } else if (ev.y > 750) {
        this.$store.state.commonTools = 750;
      } else {
        this.$store.state.commonTools = ev.y;
      }
      sessionStorage.setItem("commonTools", ev.y);
    },
    setWatermark(str, str1, str2) {
      if (this.$route.path === "/videoModule/index") {
        return false;
      }
      //水印
      if (
        document.getElementById("watermark") != undefined &&
        document.getElementById("watermark") != null &&
        document.getElementById("watermark") != ""
      ) {
        document.getElementById("watermark").remove();
      }
      //创建一个画布
      let can = document.createElement("canvas");
      //设置画布的长宽
      can.width = 200;
      can.height = 200;
      let cans = can.getContext("2d");
      //旋转角度
      cans.rotate((-20 * Math.PI) / 180);
      //设置填充绘画的颜色、渐变或者模式
      cans.fillStyle = "rgba(200, 200, 200, 0.40)";
      //设置文本内容的当前对齐方式
      cans.textAlign = "left";
      //设置在绘制文本时使用的当前文本基线
      cans.textBaseline = "Middle";
      //在画布上绘制填色的文本（输出的文本，开始绘制文本的X坐标位置，开始绘制文本的Y坐标位置）
      cans.font = "14px Vedana";
      cans.fillText(str, can.width / 8, can.height / 2);
      cans.font = "16px Vedana";
      cans.fillText(str1, can.width / 4, can.height / 3);
      cans.font = "16px Vedana";
      cans.fillText(str2, can.width / 6, can.height / 5);

      let id = "watermark";
      let div = document.createElement("div");
      div.id = id;
      div.style.pointerEvents = "none";
      div.style.top = "0px";
      div.style.left = "0px";
      div.style.position = "absolute";
      div.style.textAlign = "center";
      div.style.zIndex = "100000";
      div.style.width = "100%";
      div.style.height = "100%";
      div.style.background =
        "url(" + can.toDataURL("image/png") + ") left top repeat";
      document.getElementsByClassName("layout-main")[0].appendChild(div);
      // 每分钟刷新一次
      let _this = this;
      setTimeout(function () {
        let time = _this.$calcu.timeCycle(new Date(), "yyyy/MM/dd");
        time = new Date(time).getTime();
        if (_this.$store.state.user.servicePeriod) {
          let expirationTime = _this.$store.state.user.servicePeriod.replace(
            /-/g,
            "/"
          );
          expirationTime = new Date(expirationTime).getTime();
          if (time > expirationTime) {
            _this.setWatermark(
              _this.getTime(),
              _this.$store.state.user.name,
              "试用版"
            );
          } else {
            _this.setWatermark(
              _this.getTime(),
              _this.$store.state.user.name,
              ""
            );
          }
        } else {
          _this.setWatermark(_this.getTime(), _this.$store.state.user.name, "");
        }
      }, 60000);
    },
    getTime() {
      let str = this.$calcu.timeCycle(new Date(), "yyyy年MM月dd日 HH:mm:ss");
      return str;
    },
    /* clearTask(val) {
      let cookie = [];
      let newCookie = [];
      if (getCookie().downloadData) {
        cookie = JSON.parse(getCookie().downloadData);
      }
      if (val == 1) {
        newCookie = cookie.filter(x => x.type != 1);
        setCookie("downloadData", JSON.stringify(newCookie));
        this.$store.commit("setDownloadData");
      }
      if (val == 2) {
        newCookie = cookie.filter(x => x.type != 2);
        setCookie("downloadData", JSON.stringify(newCookie));
        this.$store.commit("setDownloadData");
      }
      if (val == 3) {
        setCookie("downloadData", "", -1);
        this.$store.commit("setDownloadData");
      }
    }, */
    // againDow(val) {
    //   let cookie = [];
    //   if (getCookie().downloadData) {
    //     cookie = JSON.parse(getCookie().downloadData);
    //   }
    //   cookie.forEach(x => {
    //     if (x.excelId == val.excelId) {
    //       this.$http({
    //         url: "api/topic/export/data",
    //         data: x.data,
    //         method: "post",
    //       });
    //       x.state = "正在建立下载通道...";
    //       x.type = "";
    //       x.result = "";
    //     }
    //   });
    //   setCookie("downloadData", JSON.stringify(cookie));
    //   this.$store.commit("setDownloadData");
    // }
    //用户类型消息提示
    async userType() {
      let res = await organType({ organId: this.$store.state.user.organizationId });
      if (res.data.code != 200) {
        return;
      }
      if (res.data.data == 0) {
        let res2 = await organTime({ organId: this.$store.state.user.organizationId });
        if (res2.data.code == 200 && res2.data.data <= 30) {
          this.$message({
            type: 'error',
            dangerouslyUseHTMLString: true,
            iconClass: "el-icon-info",
            customClass: "zb_layout_stop",
            message: `<h3 style="margin-left:10px">即将到期</h3><br/><p style="margin-left:10px">您的服务即将于${this.$calcu.timeCycle(new Date(new Date().setDate(new Date().getDate() + res2.data.data)), "yyyy-MM-dd")}日到期，为避免数据丢失请注意及时处理！</p>`
          });
        }
        sessionStorage.setItem("versionType", "1");
        this.$store.commit("setVersionType", "1");
      } else if (res.data.data == 1) {
        let res2 = await organTime({ organId: this.$store.state.user.organizationId });
        this.loginTips.url = require("../assets/img/index/loginTips.png");
        this.loginTips.title = "尊敬的用户：您当前使用的为<span style='color:#DD3B4A'>试用版</span>服务";
        this.loginTips.text = `您的试用于${this.$calcu.timeCycle(new Date(new Date().setDate(new Date().getDate() + res2.data.data)), "yyyy年MM月dd日")}到期，<br/>
        届时我们将删除您的舆情监测以及所有相关数据和服务。<br/>
        若要享受我们的完整服务，请联系您的顾问升级到<span style="color:#409EFF">专业版</span>。<br/>
        <br/>
        您的体验对我们非常重要，请分享您的反馈，<br/>
        我们会借此改进我们的服务，并在今后更好地满足用户需求。`;
        if (res.data.code == 200) {
          this.loginTips.time = res2.data.data;
        }
        this.loginTipsShow = true;
        sessionStorage.setItem("versionType", "2");
        this.$store.commit("setVersionType", "2");
      } else if (res.data.data == 2) {
        this.loginTips.url = require("../assets/img/index/loginErr.png");
        this.loginTips.title = "您无法继续使用，因为您的服务已到期";
        this.loginTips.text = `使用期已过期，若要继续使用，您需要联系您的顾问续订我们的服务。<br/>
        您的监测信息将存储1年。若要详细了解可用信息，请咨询您的商务顾问。<br/>
        <br/>
        您的体验对我们非常重要，请分享您的反馈，<br/>
        我们会借此改进我们的服务，并在今后更好地满足用户需求。`;
        this.loginTipsShow = true;
        sessionStorage.setItem("versionType", "3");
        this.$store.commit("setVersionType", "3");
      }
    },
    loginTipsClose() {
      this.loginTipsShow = false;
      if (this.loginTips.time != -1 && this.loginTips.time <= 30) {
        this.$message({
          type: 'error',
          dangerouslyUseHTMLString: true,
          iconClass: "el-icon-info",
          customClass: "zb_layout_stop",
          message: `<h3 style="margin-left:10px">即将到期</h3><br/><p style="margin-left:10px">您的<b>试用版本</b>即将于${this.$calcu.timeCycle(new Date(new Date().setDate(new Date().getDate() + this.loginTips.time)), "yyyy-MM-dd")}日到期，为避免数据丢失请注意及时处理！</p>`
        });
      }
    }
  },
  created() {
    if (
      !this.$store.state.user.loginState ||
      this.$store.state.user.tokenTypeWeb !== "!"
    ) {
      this.$router.push({
        path: "/login",
      });
    }
    this.timeDreFresh();
    if (sessionStorage.getItem("versionType")) {
      this.$store.commit("setVersionType", sessionStorage.getItem("versionType"));
    } else {
      this.userType();
    }
  },
  mounted() {
    let time = this.$calcu.timeCycle(new Date(), "yyyy/MM/dd");
    time = new Date(time).getTime();
    if (this.$store.state.user.servicePeriod) {
      let expirationTime = this.$store.state.user.servicePeriod.replace(
        /-/g,
        "/"
      );
      expirationTime = new Date(expirationTime).getTime();
      if (time > expirationTime) {
        this.setWatermark(
          this.getTime(),
          this.$store.state.user.name,
          "试用版"
        );
      } else {
        this.setWatermark(this.getTime(), this.$store.state.user.name, "");
      }
    } else {
      this.setWatermark(this.getTime(), this.$store.state.user.name, "");
    }
  },
};
</script>

<style scoped>
.layout {
  width: 100%;
  height: 100%;
}

.layout-header {
  width: calc(100% - 228px);
  height: 80px;
  margin-left: 228px;
  position: fixed;
  z-index: 22;
  -webkit-transition: all 0.28s;
  transition: all 0.28s;
}

.layout-header.layout-header-long {
  width: calc(100% - 96px);
  margin-left: 96px;
}

.layout-aside {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 228px;
  transition: width 0.28s;
  overflow: hidden;
  background: linear-gradient(180deg, #2e59ec 0%, #2537a6 100%);
  z-index: 22;
}

.layout-aside-video {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 228px;
  transition: width 0.28s;
  overflow: hidden;
  background: #232431;
  z-index: 22;
}

.layout-main {
  height: 100%;
  padding-top: 80px;
  -webkit-transition: margin-left 0.28s;
  transition: margin-left 0.28s;
  margin-left: 228px;
  position: relative;
}

.layout-min .layout-aside {
  width: 96px;
}

.layout-min .layout-aside-video {
  width: 96px;
}

.layout-min .layout-main {
  margin-left: 96px;
}

.layout-warning {
  z-index: 9999;
  width: 424px;
  height: 272px;
  background: #ffffff;
  box-shadow: 0px 3px 18px -3px rgba(0, 0, 0, 0.16);
  border-radius: 8px 8px 0px 0px;
  position: fixed;
  right: 0;
  bottom: 0;
}

.layout-warning-show {
  display: block;
}

.download-Wait {
  display: inline-block;
  margin-bottom: 15px;
}

.exportBox {
  cursor: pointer;
  width: 140px;
  height: 40px;
  border: 1px solid #088dfb;
  position: fixed;
  right: 40px;
  bottom: 80px;
  border-radius: 4px;
  background-color: #f8f8f8;
  color: #9f9999;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: 50;
}

.exportBox span {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #f95e5a;
  color: white;
  line-height: 26px;
  margin-left: 20px;
  text-align: center;
  font-size: 14px;
}

.listFooter {
  box-sizing: border-box;
  padding-top: 10px;
  border-top: 1px solid #e4e4e4;
  text-align: right;
}

.listBody div span:nth-child(2) {
  margin-right: 55px;
  color: #999999;
  display: inline-block;
  width: 20%;
}

.listBody div span:first-child {
  margin-right: 100px;
  color: #999999;
  display: inline-block;
  width: 46%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.listBody h2 {
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  color: #cccccc;
  margin-top: 10px;
}

.listBody div {
  font-size: 14px;
  margin: 10px 0px;
  display: flex;
  align-items: center;
}

.listBody {
  width: 100%;
  height: 190px;
  background-color: #f2f2f2;
  box-sizing: border-box;
  padding: 1px 10px;
  margin-bottom: 10px;
  overflow: auto;
}

.zb_layout_tips {
  display: block;
  width: 100%;
  text-align: center;
}

#zb_layout_tips_main {
  text-align: center;
}

#zb_layout_tips_main h2 {
  margin: 20px 0;
}

#zb_layout_tips_main p {
  line-height: 30px;
}

.zb_layout_stop {}
</style>
